import React, { ReactNode } from 'react';
import { 
  Box, 
  Paper, 
  IconButton, 
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';
import { 
  BarChart3, 
  Users, 
  MessageSquare, 
  Menu,
  Bell,
  Home,
  UserPlus,
  UsersRound,
  Settings,
  FileText,
  PieChart,
  Send,
  History,
  FileCode,
  Building2,
  ChevronDown,
  ChevronRight,
  Megaphone,
  LineChart,
  LucideIcon
} from 'lucide-react';
import { UserButton } from "@clerk/clerk-react";
import { useNavigate, useLocation } from 'react-router-dom';

interface MenuItem {
  key: string;
  label: string;
  icon: LucideIcon;
  path?: string;
  subItems?: MenuItem[];
}

interface ModernMainProps {
  children: ReactNode;
}

interface MenuState {
  customers: boolean;
  messaging: boolean;
  analytics: boolean;
  [key: string]: boolean;
}

const ModernMain: React.FC<ModernMainProps> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(true);
  const [menuExpanded, setMenuExpanded] = React.useState<MenuState>({
    customers: true,
    messaging: false,
    analytics: false
  });
  const navigate = useNavigate();
  const location = useLocation();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleMenu = (key: string) => {
    setMenuExpanded(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const menuItems: MenuItem[] = [
    {
      key: 'dashboard',
      label: 'Dashboard',
      icon: Home,
      path: '/dashboard'
    },
    {
      key: 'customers',
      label: 'Customer Management',
      icon: Users,
      subItems: [
        { key: 'all-customers', label: 'All Customers', icon: UsersRound, path: '/dashboard/customers' },
        { key: 'add-customer', label: 'Add Customer', icon: UserPlus, path: '/dashboard/customers/add' },
        { key: 'customer-groups', label: 'Customer Groups', icon: Building2, path: '/dashboard/customers/groups' }
      ]
    },
    {
      key: 'messaging',
      label: 'Messaging',
      icon: MessageSquare,
      subItems: [
        { key: 'send-message', label: 'Send Message', icon: Send, path: '/dashboard/messages/send' },
        { key: 'message-history', label: 'Message History', icon: History, path: '/dashboard/messages/history' },
        { key: 'templates', label: 'Templates', icon: FileCode, path: '/dashboard/messages/templates' },
        { key: 'campaigns', label: 'Campaigns', icon: Megaphone, path: '/dashboard/messages/campaigns' }
      ]
    },
    {
      key: 'analytics',
      label: 'Analytics',
      icon: BarChart3,
      subItems: [
        { key: 'overview', label: 'Overview', icon: PieChart, path: '/dashboard/analytics' },
        { key: 'campaign-stats', label: 'Campaign Stats', icon: LineChart, path: '/dashboard/analytics/campaigns' },
        { key: 'reports', label: 'Reports', icon: FileText, path: '/dashboard/analytics/reports' }
      ]
    },
    {
      key: 'settings',
      label: 'Settings',
      icon: Settings,
      path: '/dashboard/settings'
    }
  ];

  const renderMenuItem = (item: MenuItem, depth = 0) => {
    const isActive = location.pathname === item.path;
    const hasSubItems = item.subItems && item.subItems.length > 0;
    const isExpanded = menuExpanded[item.key];

    const handleClick = () => {
      if (hasSubItems) {
        toggleMenu(item.key);
      } else if (item.path) {
        navigate(item.path);
      }
    };

    const Icon = item.icon;

    return (
      <React.Fragment key={item.key}>
        <ListItem
          sx={{
            pl: depth * 2 + 2,
            py: 1,
            cursor: 'pointer',
            bgcolor: isActive ? 'primary.lighter' : 'transparent',
            color: isActive ? 'primary.main' : 'grey.700',
            '&:hover': {
              bgcolor: isActive ? 'primary.lighter' : 'grey.100',
            },
          }}
          onClick={handleClick}
        >
          <ListItemIcon sx={{ minWidth: 40, color: 'inherit' }}>
            <Icon size={20} />
          </ListItemIcon>
          {sidebarOpen && (
            <>
              <ListItemText 
                primary={item.label}
                primaryTypographyProps={{
                  fontSize: '0.875rem',
                  fontWeight: isActive ? 600 : 400
                }}
              />
              {hasSubItems && (
                <IconButton edge="end" size="small">
                  {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
                </IconButton>
              )}
            </>
          )}
        </ListItem>
        {hasSubItems && sidebarOpen && (
          <Collapse in={isExpanded} timeout="auto">
            <List disablePadding>
              {item.subItems?.map(subItem => renderMenuItem(subItem, depth + 1))}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    );
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      {/* Sidebar */}
      <Paper 
        elevation={0}
        sx={{ 
          width: sidebarOpen ? 256 : 72,
          flexShrink: 0,
          borderRight: '1px solid',
          borderColor: 'grey.200',
          transition: 'width 0.2s ease-in-out',
          overflowX: 'hidden',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
        }}
      >
        {/* Logo/Brand */}
        <Box sx={{ p: 3, display: 'flex', alignItems: 'center', gap: 2 }}>
          <IconButton onClick={toggleSidebar}>
            <Menu />
          </IconButton>
          {sidebarOpen && (
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              rani CRM
            </Typography>
          )}
        </Box>

        {/* Navigation Menu */}
        <List sx={{ px: 2 }}>
          {menuItems.map(item => renderMenuItem(item))}
        </List>
      </Paper>

      {/* Main Content */}
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1, 
          ml: sidebarOpen ? '256px' : '72px',
          transition: 'margin-left 0.2s ease-in-out'
        }}
      >
        {/* Top Bar */}
        <Paper 
          elevation={0} 
          sx={{ 
            borderBottom: '1px solid', 
            borderColor: 'grey.200',
            position: 'sticky',
            top: 0,
            zIndex: 1100,
            backgroundColor: 'background.paper'
          }}
        >
          <Box sx={{ px: 3, py: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
            </Box>
            
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <IconButton>
                <Bell />
              </IconButton>
              <UserButton afterSignOutUrl="/sign-in" />
            </Box>
          </Box>
        </Paper>

        {/* Page Content */}
        <Box sx={{ p: 3 }}>
          {children}  {/* Render the children directly */}
        </Box>
      </Box>
    </Box>
  );
};

export default ModernMain;