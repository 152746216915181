import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Box,
  Alert,
  Chip,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

interface MessageResult {
  phone: string;
  status: 'success' | 'failed';
  messageId?: string;
  error?: string;
}

interface SendMessageDialogProps {
  open: boolean;
  handleClose: () => void;
  message: string;
  results: MessageResult[];
  summary: {
    total: number;
    successful: number;
    failed: number;
  };
}

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const SendMessageDialog = ({
  open,
  handleClose,
  message,
  results,
  summary,
}: SendMessageDialogProps) => {
  const failedMessages = results.filter(r => r.status === 'failed');
  
  return (
    <Dialog 
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="message-results-dialog"
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Message Sending Results
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent dividers>
        <Alert 
          severity={summary.failed === 0 ? 'success' : 'warning'}
          sx={{ mb: 2 }}
        >
          Successfully sent to {summary.successful} out of {summary.total} recipients
        </Alert>

        <Typography variant="subtitle2" gutterBottom>
          Message Content:
        </Typography>
        <Typography 
          variant="body2" 
          color="text.secondary" 
          sx={{ 
            p: 1.5, 
            bgcolor: 'action.hover',
            borderRadius: 1,
            mb: 2
          }}
        >
          "{message}"
        </Typography>

        {failedMessages.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" color="error" gutterBottom>
              Failed Deliveries ({failedMessages.length}):
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {failedMessages.map((result, index) => (
                <StyledChip
                  key={index}
                  label={result.phone}
                  color="error"
                  variant="outlined"
                  size="small"
                  title={result.error}
                />
              ))}
            </Box>
          </Box>
        )}
      </DialogContent>
      
      <DialogActions>
        <Button 
          onClick={handleClose} 
          variant="contained" 
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendMessageDialog;