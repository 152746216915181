import { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  IconButton,
  TextField,
  Chip,
  Avatar,
  Menu,
  MenuItem,
  Card,
  CardContent,
  Alert,
  LinearProgress,
  AlertColor
} from '@mui/material';
import { 
  UserPlus,
  MoreVertical,
  Download,
  Upload,
  Search,
  Mail,
  Phone,
  Filter,
  X
} from 'lucide-react';
import axios from 'axios';

// Types
interface Customer {
  id: number;
  first_name: string;
  last_name: string;
  email?: string;
  phone?: string;
  mobile?: string;
  customer_group_id?: number;
  is_active: boolean;
  created_at: string;
}

interface AlertInfo {
  show: boolean;
  message: string;
  severity: AlertColor;
}

interface CustomerCardProps {
  customer: Customer;
  selected: boolean;
  onSelect: () => void;
}

const CustomerCard: React.FC<CustomerCardProps> = ({ customer, selected, onSelect }) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
  };

  const getInitials = (name: string): string => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  return (
    <Card 
      elevation={0}
      className={`border cursor-pointer transition-all ${
        selected ? 'border-blue-500 bg-blue-50' : 'hover:border-gray-400'
      }`}
      onClick={onSelect}
    >
      <CardContent>
        <Box className="flex items-start justify-between mb-4">
          <Box className="flex items-center gap-3">
            <Avatar className="bg-blue-100 text-blue-600">
              {getInitials(`${customer.first_name} ${customer.last_name}`)}
            </Avatar>
            <Box>
              <Typography variant="subtitle1" className="font-medium">
                {customer.first_name} {customer.last_name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Customer since {new Date(customer.created_at).toLocaleDateString()}
              </Typography>
            </Box>
          </Box>
          
          <IconButton size="small" onClick={handleMenuClick}>
            <MoreVertical size={16} />
          </IconButton>
        </Box>

        <Box className="space-y-2">
          {customer.email && (
            <Box className="flex items-center gap-2">
              <Mail size={16} className="text-gray-400" />
              <Typography variant="body2">{customer.email}</Typography>
            </Box>
          )}
          {customer.phone && (
            <Box className="flex items-center gap-2">
              <Phone size={16} className="text-gray-400" />
              <Typography variant="body2">{customer.phone}</Typography>
            </Box>
          )}
        </Box>

        <Box className="mt-4 flex gap-2">
          <Chip 
            size="small" 
            label={customer.customer_group_id ? 'VIP' : 'Regular'}
            color={customer.customer_group_id ? 'primary' : 'default'}
            variant="outlined"
          />
          {customer.is_active && (
            <Chip 
              size="small" 
              label="Active" 
              color="success" 
              variant="outlined"
            />
          )}
        </Box>
      </CardContent>

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        onClick={(e) => e.stopPropagation()}
      >
        <MenuItem>Edit Customer</MenuItem>
        <MenuItem>View Details</MenuItem>
        <MenuItem>Send Message</MenuItem>
        <MenuItem className="text-red-500">Deactivate</MenuItem>
      </Menu>
    </Card>
  );
};

const CustomersPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [_showAddDialog, setShowAddDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<number[]>([]);
  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    show: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const userId = localStorage.getItem("USER_ID");
      const response = await axios.get<Customer[]>(`${import.meta.env.REACT_APP_API_URL || 'http://localhost:3000'}/customers`, {
        params: { user_id: Number(userId) }
      });
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
      showAlert('Failed to fetch customers', 'error');
    } finally {
      setLoading(false);
    }
  };

  const showAlert = (message: string, severity: AlertColor = 'success') => {
    setAlertInfo({ show: true, message, severity });
    setTimeout(() => setAlertInfo({ show: false, message: '', severity: 'success' }), 5000);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCustomerSelect = (customerId: number) => {
    setSelectedCustomers(prev =>
      prev.includes(customerId)
        ? prev.filter(id => id !== customerId)
        : [...prev, customerId]
    );
  };

  const filteredCustomers = customers.filter(customer => 
    `${customer.first_name} ${customer.last_name}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
    customer.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    customer.phone?.includes(searchQuery)
  );

  return (
    <Box className="p-6">
      {/* Header */}
      <Box className="mb-6 flex justify-between items-center">
        <Typography variant="h5" className="font-semibold">
          Customers
          <Typography component="span" color="text.secondary" className="ml-2">
            ({customers.length})
          </Typography>
        </Typography>
        
        <Box className="flex gap-3">
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<Filter size={20} />}
          >
            Filters
          </Button>
          
          <Button
            variant="contained"
            startIcon={<UserPlus size={20} />}
            onClick={() => setShowAddDialog(true)}
          >
            Add Customer
          </Button>
          
          <IconButton onClick={handleMenuClick}>
            <MoreVertical size={20} />
          </IconButton>
          
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose}>
              <Download size={18} className="mr-2" />
              Export
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <Upload size={18} className="mr-2" />
              Import
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* Search and Filters */}
      <Paper elevation={0} className="mb-6 p-4 border">
        <Box className="flex gap-4 items-center">
          <Box className="flex-1">
            <TextField
              fullWidth
              placeholder="Search customers..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: <Search size={20} className="mr-2 text-gray-400" />,
                endAdornment: searchQuery && (
                  <IconButton size="small" onClick={() => setSearchQuery('')}>
                    <X size={16} />
                  </IconButton>
                )
              }}
            />
          </Box>
          
          {selectedCustomers.length > 0 && (
            <Box className="flex gap-2">
              <Button
                variant="outlined"
                startIcon={<Mail size={18} />}
              >
                Message Selected
              </Button>
              <Typography color="text.secondary" className="flex items-center">
                {selectedCustomers.length} selected
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>

      {/* Customers Grid */}
      {loading ? (
        <LinearProgress />
      ) : (
        <Box className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredCustomers.map((customer) => (
            <CustomerCard
              key={customer.id}
              customer={customer}
              selected={selectedCustomers.includes(customer.id)}
              onSelect={() => handleCustomerSelect(customer.id)}
            />
          ))}
        </Box>
      )}

      {/* Alert */}
      {alertInfo.show && (
        <Alert 
          severity={alertInfo.severity}
          className="fixed bottom-4 right-4"
          onClose={() => setAlertInfo({ show: false, message: '', severity: 'success' })}
        >
          {alertInfo.message}
        </Alert>
      )}
    </Box>
  );
};

export default CustomersPage;