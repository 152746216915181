import { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  TextField,
  Button,
  Chip,
  Alert,
  Divider,
  Grid
} from '@mui/material';
import { Send as SendIcon, History as HistoryIcon } from '@mui/icons-material';
import axios, { AxiosError } from 'axios';
import SendMessageDialog from '../components/SendMessageDIalog';
import Dashboard from '../components/Dashboard'; // Add this import

// Define interfaces for type safety
interface Customer {
  id: number;
  first_name: string;
  last_name: string;
  phone?: string;
  mobile?: string;
}

interface Template {
  id: number;
  name: string;
  content: string;
}

interface AlertType {
  severity: 'error' | 'warning' | 'info' | 'success';
  message: string;
}
interface ApiError {
  details: string;
}

interface SendResult {
  summary: {
    total: number;
    successful: number;
    failed: number;
  };
  results: Array<{
    phone: string;
    status: 'success' | 'failed';
    messageId?: string;
    error?: string;
  }>;
}

const SendMessagePage = () => {
  const [selectedCustomers, setSelectedCustomers] = useState<Customer[]>([]);
  const [message, setMessage] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [sendResult, setSendResult] = useState<SendResult | null>(null);
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [templates] = useState<Template[]>([
    { id: 1, name: 'Welcome Message', content: 'Welcome to our store! We appreciate your business.' },
    { id: 2, name: 'Thank You', content: 'Thank you for your recent visit! We hope to see you again soon.' },
    { id: 3, name: 'Special Offer', content: "Don't miss our special offer this week!" }
  ]);

  const handleSend = async () => {
    if (!selectedCustomers.length) {
      setAlert({ severity: 'error', message: 'Please select at least one recipient' });
      return;
    }
    if (!message.trim()) {
      setAlert({ severity: 'error', message: 'Please enter a message' });
      return;
    }

    try {
      const phones = selectedCustomers.map(customer => customer.phone || customer.mobile).filter(Boolean);
      const response = await axios.post<SendResult>(`${import.meta.env.REACT_APP_API_URL || 'http://localhost:3000'}/messages`, {
        phones,
        message: message.trim()
      });

      setSendResult(response.data);
      setShowDialog(true);
    } catch (error) {
      const axiosError = error as AxiosError<ApiError>;
      setAlert({ 
        severity: 'error', 
        message: axiosError.response?.data?.details || 'Failed to send message' 
      });
    }
  };

  const handleTemplateClick = (template: Template) => {
    setMessage(template.content);
  };

  const removeCustomer = (customerToRemove: Customer) => {
    setSelectedCustomers(prev => 
      prev.filter(customer => customer.id !== customerToRemove.id)
    );
  };

  return (
    <Dashboard>
      <Box className="p-6">
        <Typography variant="h4" className="mb-6">Send Message</Typography>
        
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Paper className="p-4">
              {alert && (
                <Alert 
                  severity={alert.severity} 
                  onClose={() => setAlert(null)} 
                  className="mb-4"
                >
                  {alert.message}
                </Alert>
              )}

              <Box className="mb-4">
                <Typography variant="subtitle1" className="mb-2">
                  Recipients ({selectedCustomers.length})
                </Typography>
                <Box className="flex flex-wrap gap-2">
                  {selectedCustomers.map((customer) => (
                    <Chip
                      key={customer.id}
                      label={`${customer.first_name} ${customer.last_name}`}
                      onDelete={() => removeCustomer(customer)}
                      className="mb-2"
                    />
                  ))}
                  {!selectedCustomers.length && (
                    <Typography variant="body2" color="text.secondary">
                      No recipients selected. Please select from customer list.
                    </Typography>
                  )}
                </Box>
              </Box>

              <Divider className="my-4" />

              <TextField
                fullWidth
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type your message here..."
                variant="outlined"
                className="mb-4"
                inputProps={{ maxLength: 1600 }}
                helperText={`${message.length}/1600 characters`}
              />

              <Box className="flex justify-between items-center">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SendIcon />}
                  onClick={handleSend}
                  disabled={!selectedCustomers.length || !message.trim()}
                >
                  Send Message
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<HistoryIcon />}
                  onClick={() => {/* TODO: Implement message history view */}}
                >
                  Message History
                </Button>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper className="p-4">
              <Typography variant="h6" className="mb-4">Message Templates</Typography>
              {templates.map((template) => (
                <Box 
                  key={template.id}
                  className="mb-3 p-3 border rounded cursor-pointer hover:bg-gray-50"
                  onClick={() => handleTemplateClick(template)}
                >
                  <Typography variant="subtitle2" className="mb-1">
                    {template.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" noWrap>
                    {template.content}
                  </Typography>
                </Box>
              ))}
            </Paper>
          </Grid>
        </Grid>

        {showDialog && sendResult && (
          <SendMessageDialog
            open={showDialog}
            handleClose={() => {
              setShowDialog(false);
              setSendResult(null);
              if (sendResult.summary.successful > 0) {
                setMessage('');
                setSelectedCustomers([]);
              }
            }}
            message={message}
            results={sendResult.results}
            summary={sendResult.summary}
          />
        )}
      </Box>
    </Dashboard>
  );
};

export default SendMessagePage;