import React from 'react';
import { Box } from '@mui/material';

interface AuthLayoutProps {
  children: React.ReactNode;
  mode: 'sign-in' | 'sign-up';
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children, mode }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      {/* Left side - Blue background with content */}
      <Box
        sx={{
          flex: '1',
          bgcolor: 'rgb(37, 99, 235)',
          color: 'white',
          display: { xs: 'none', md: 'flex' },
          flexDirection: 'column',
          justifyContent: 'center',
          p: 8,
        }}
      >
        <h1 className="text-4xl font-bold mb-4">
          {mode === 'sign-in' ? 'Welcome back!' : 'Welcome to RANI CRM!'}
        </h1>
        <p className="text-xl mb-8">
          Manage your customers and communications effectively with RANI CRM.
        </p>
        
        <div className="space-y-6">
          <div className="flex items-center">
            <div className="rounded-full bg-blue-500 p-2 mr-4">
              <svg 
                className="w-6 h-6" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <span className="text-lg">Efficient Customer Management</span>
          </div>
          
          <div className="flex items-center">
            <div className="rounded-full bg-blue-500 p-2 mr-4">
              <svg 
                className="w-6 h-6" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <span className="text-lg">SMS Marketing Tools</span>
          </div>
          
          <div className="flex items-center">
            <div className="rounded-full bg-blue-500 p-2 mr-4">
              <svg 
                className="w-6 h-6" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <span className="text-lg">Analytics & Reporting</span>
          </div>
        </div>
      </Box>

      {/* Right side - Auth form */}
      <Box
        sx={{
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 4,
        }}
      >
        <Box
          sx={{
            maxWidth: '400px',
            width: '100%',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default AuthLayout;