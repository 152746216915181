import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  Button,
  CircularProgress
} from "@mui/material";
import {
  Users,
  MessageSquare,
  BarChart3,
  Settings
} from "lucide-react";

interface DashboardCardProps {
  title: string;
  value: string | number;
  description: string;
  icon: React.ReactNode;
  onClick?: () => void;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  value,
  description,
  icon,
  onClick
}) => (
  <Card 
    elevation={0} 
    className="border cursor-pointer hover:border-blue-500 transition-all"
    onClick={onClick}
  >
    <CardContent>
      <Box className="flex items-center justify-between mb-4">
        <Typography variant="h6" className="font-medium">
          {title}
        </Typography>
        <Box className="p-2 rounded-full bg-blue-50 text-blue-600">
          {icon}
        </Box>
      </Box>
      <Typography variant="h4" className="font-semibold mb-1">
        {value}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </CardContent>
  </Card>
);

const MainPage = () => {
  const [loading, setLoading] = useState(true);
  const { user } = useUser();
  const navigate = useNavigate();

  // Simulated data loading
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <Box className="flex items-center justify-center h-full">
        <CircularProgress />
      </Box>
    );
  }

  const quickActions = [
    {
      title: "Customers",
      value: "1,234",
      description: "Total active customers",
      icon: <Users size={24} />,
      onClick: () => navigate("/dashboard/customers")
    },
    {
      title: "Messages",
      value: "856",
      description: "Messages sent this month",
      icon: <MessageSquare size={24} />,
      onClick: () => navigate("/dashboard/messages/send")
    },
    {
      title: "Analytics",
      value: "89%",
      description: "Message delivery rate",
      icon: <BarChart3 size={24} />,
      onClick: () => navigate("/dashboard/analytics")
    },
    {
      title: "Settings",
      value: "System",
      description: "Configure your workspace",
      icon: <Settings size={24} />,
      onClick: () => navigate("/dashboard/settings")
    }
  ];

  return (
    <Box className="p-6">
      {/* Welcome Section */}
      <Box className="mb-8">
        <Typography variant="h4" className="font-semibold mb-2">
          Welcome back, {user?.firstName || 'User'}!
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Here's what's happening with your business today.
        </Typography>
      </Box>

      {/* Quick Actions Grid */}
      <Grid container spacing={3} className="mb-6">
        {quickActions.map((action, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <DashboardCard {...action} />
          </Grid>
        ))}
      </Grid>

      {/* Recent Activity Section */}
      <Paper elevation={0} className="p-4 border">
        <Box className="flex justify-between items-center mb-4">
          <Typography variant="h6" className="font-medium">
            Recent Activity
          </Typography>
          <Button
            variant="outlined"
            size="small"
            onClick={() => navigate("/dashboard/customers")}
          >
            View All
          </Button>
        </Box>
        <Typography color="text.secondary">
          Your recent activity will appear here
        </Typography>
      </Paper>
    </Box>
  );
};

export default MainPage;