import { Box, Button, Paper } from '@mui/material';
import { UserPlus } from 'lucide-react';
import CustomerGroups from '../components/CustomerGroups';
import AddCustomerGroup from '../components/AddCustomerGroup';
import { useState } from 'react';

const CustomerGroupsPage = () => {
  const [addGroupOpen, setAddGroupOpen] = useState(false);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <h1 className="text-2xl font-semibold">Customer Groups</h1>
        <Button
          variant="contained"
          startIcon={<UserPlus size={20} />}
          onClick={() => setAddGroupOpen(true)}
        >
          Add Group
        </Button>
      </Box>

      <Paper sx={{ p: 2 }}>
        <CustomerGroups />
      </Paper>

      {addGroupOpen && (
        <AddCustomerGroup
          open={addGroupOpen}
          onClose={() => setAddGroupOpen(false)}
        />
      )}
    </Box>
  );
};

export default CustomerGroupsPage;