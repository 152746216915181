import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import PeopleIcon from "@mui/icons-material/People";
import ListItem from "./listItem";

export const mainListItems = (
  <React.Fragment>
    
    <ListItem title="Customers" Icon={PeopleIcon} link="customers" />
    <ListItemButton>
      
      
    </ListItemButton>
    
  </React.Fragment>
);


