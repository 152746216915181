const ScrollableContainer = ({ 
  children, 
  className = '' 
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div 
      className={`
        overflow-y-auto
        scrollbar-thin
        scrollbar-track-gray-800
        scrollbar-thumb-gray-600
        hover:scrollbar-thumb-gray-500
        scrollbar-thumb-rounded-full
        scrollbar-track-rounded-full
        ${className}
      `}
    >
      {children}
    </div>
  );
};

export default ScrollableContainer;