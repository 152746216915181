import { useNavigate } from 'react-router-dom';
import { MessageSquare, Users, BarChart3, Calendar, Check, ArrowRight } from 'lucide-react';

const LandingPage = () => {
  const navigate = useNavigate();

  // Smooth scroll function
  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 overflow-x-hidden">
      {/* Sticky Navigation */}
      <nav className="sticky top-0 z-50 bg-gray-900/95 backdrop-blur-sm">
        <div className="container mx-auto px-4 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <MessageSquare className="h-8 w-8 text-blue-500" />
              <span className="ml-2 text-2xl font-bold text-white">rani CRM</span>
            </div>
            <div className="hidden md:flex space-x-8">
              <button 
                onClick={() => scrollToSection('features')} 
                className="text-gray-300 hover:text-white transition-colors"
              >
                Features
              </button>
              <button 
                onClick={() => scrollToSection('pricing')} 
                className="text-gray-300 hover:text-white transition-colors"
              >
                Pricing
              </button>
              <button 
                onClick={() => scrollToSection('contact')} 
                className="text-gray-300 hover:text-white transition-colors"
              >
                Contact
              </button>
            </div>
            <div className="flex space-x-4">
              <button 
                onClick={() => navigate('/sign-in')} 
                className="text-blue-500 hover:text-blue-400 transition-colors"
              >
                Sign In
              </button>
              <button 
                onClick={() => navigate('/sign-in')} 
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="container mx-auto px-4 py-16">
        <div className="text-center max-w-4xl mx-auto">
          <h1 className="text-4xl md:text-6xl font-bold text-white mb-6 leading-tight">
            Streamline Your Customer Communication
          </h1>
          <p className="text-xl text-gray-300 mb-8 leading-relaxed">
            Powerful SMS marketing and customer relationship management in one platform.
            Reach your customers instantly and build lasting relationships.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <button
              onClick={() => navigate('/sign-in')}
              className="bg-blue-500 text-white px-8 py-4 rounded-lg text-lg font-semibold hover:bg-blue-600 transition-colors transform hover:scale-105 duration-200"
            >
              Start Free Trial
            </button>
            <button
              onClick={() => scrollToSection('demo')}
              className="bg-gray-700 text-white px-8 py-4 rounded-lg text-lg font-semibold hover:bg-gray-600 transition-colors transform hover:scale-105 duration-200"
            >
              Watch Demo
            </button>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div id="features" className="bg-gray-800 py-20 scroll-mt-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Everything You Need to Grow Your Business
          </h2>
          
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                icon: <MessageSquare className="h-8 w-8 text-blue-500" />,
                title: "Bulk SMS Campaigns",
                description: "Send personalized messages to thousands of customers with just a few clicks."
              },
              {
                icon: <Users className="h-8 w-8 text-blue-500" />,
                title: "Customer Management",
                description: "Keep track of all your customer interactions and data in one place."
              },
              {
                icon: <Calendar className="h-8 w-8 text-blue-500" />,
                title: "Message Scheduling",
                description: "Schedule your messages to be sent at the perfect time for maximum engagement."
              },
              {
                icon: <BarChart3 className="h-8 w-8 text-blue-500" />,
                title: "Analytics & Reporting",
                description: "Get detailed insights into your campaign performance and customer engagement."
              }
            ].map((feature, index) => (
              <div 
                key={index} 
                className="bg-gray-700 p-6 rounded-lg transform hover:scale-105 transition-transform duration-200"
              >
                <div className="mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold text-white mb-2">{feature.title}</h3>
                <p className="text-gray-300">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Pricing Section */}
      <div id="pricing" className="py-20 scroll-mt-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Simple, Transparent Pricing
          </h2>

          <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            {[
              {
                title: "Starter",
                price: "49",
                features: [
                  "Up to 1,000 SMS/month",
                  "Basic customer management",
                  "Campaign analytics",
                  "Email support"
                ]
              },
              {
                title: "Professional",
                price: "99",
                features: [
                  "Up to 5,000 SMS/month",
                  "Advanced customer management",
                  "Custom templates",
                  "Priority support"
                ]
              },
              {
                title: "Enterprise",
                price: "199",
                features: [
                  "Unlimited SMS",
                  "Full feature access",
                  "Custom integrations",
                  "24/7 support"
                ]
              }
            ].map((plan, index) => (
              <div 
                key={index} 
                className="bg-gray-700 rounded-lg p-8 transform hover:scale-105 transition-transform duration-200"
              >
                <h3 className="text-xl font-semibold text-white mb-4">{plan.title}</h3>
                <div className="text-3xl font-bold text-white mb-6">
                  ${plan.price}
                  <span className="text-lg font-normal text-gray-400">/month</span>
                </div>
                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature, fIndex) => (
                    <li key={fIndex} className="flex items-center text-gray-300">
                      <Check className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0" />
                      {feature}
                    </li>
                  ))}
                </ul>
                <button 
                  onClick={() => navigate('/sign-in')}
                  className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Get Started
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div id="contact" className="bg-blue-600 py-16 scroll-mt-20">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold text-white mb-4">
            Ready to Transform Your Customer Communication?
          </h2>
          <p className="text-xl text-blue-100 mb-8">
            Join thousands of businesses that trust rani CRM for their SMS marketing needs.
          </p>
          <button
            onClick={() => navigate('/sign-in')}
            className="inline-flex items-center bg-white text-blue-600 px-8 py-4 rounded-lg text-lg font-semibold hover:bg-gray-100 transition-colors transform hover:scale-105 duration-200"
          >
            Start Your Free Trial
            <ArrowRight className="ml-2 h-5 w-5" />
          </button>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-900 py-12">
        <div className="container mx-auto px-4">
          {/* Footer content remains the same */}
          <div className="grid md:grid-cols-4 gap-8">
            <div>
              <div className="flex items-center mb-4">
                <MessageSquare className="h-6 w-6 text-blue-500" />
                <span className="ml-2 text-xl font-bold text-white">rani CRM</span>
              </div>
              <p className="text-gray-400">
                Powerful SMS marketing and customer relationship management platform.
              </p>
            </div>

            <div>
              <h4 className="text-white font-semibold mb-4">Product</h4>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-white">Features</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">Pricing</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">API</a></li>
              </ul>
            </div>
            <div>
              <h4 className="text-white font-semibold mb-4">Company</h4>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-white">About</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">Blog</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">Careers</a></li>
              </ul>
            </div>
            <div>
              <h4 className="text-white font-semibold mb-4">Legal</h4>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-white">Privacy</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">Terms</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">Security</a></li>
              </ul>
            </div>
          </div>
          <div className="border-t border-gray-800 mt-12 pt-8 text-center text-gray-400">
            <p>&copy; {new Date().getFullYear()} rani CRM. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;