// error.tsx
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Error = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1); // This will go back to the previous page
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <Player
        autoplay
        loop
        src="https://lottie.host/18f277ea-db66-4d03-bd1f-c51f84f24896/v9rQn8X0R8.json"
        style={{ height: "75%", width: "75%" }}
      >
        <Controls
          visible={false}
          buttons={["play", "repeat", "frame", "debug"]}
        />
      </Player>
      
      <Button
        onClick={handleClick}
        className="mt-8"
        variant="contained"
        sx={{
          borderRadius: 35,
          backgroundColor: "#8c8c8c",
          padding: "18px 36px",
          fontSize: "18px",
          maxWidth: "600px",
          width: "100%"
        }}
      >
        Page Not Found! Click here to go back
      </Button>
    </div>
  );
};

export default Error;