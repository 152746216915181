import { 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow,
  TablePagination,
  TableContainer,
  IconButton,
  Box,
  Alert,
  Snackbar,
  CircularProgress,
  Chip
} from '@mui/material';
import { Edit as EditIcon, Trash2 as DeleteIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import axios from 'axios';

interface CustomerGroup {
  id: number;
  name: string;
  description: string;
  member_count: number;
  created_at: string;
  last_message_sent: string | null;
}

interface AlertState {
  open: boolean;
  message: string;
  severity: 'success' | 'error' | 'info' | 'warning';
}

const API_BASE_URL = import.meta.env.VITE_API_URL || 'https://crm.raniii.com:3000/api/v1';

export default function CustomerGroups() {
  const [groups, setGroups] = useState<CustomerGroup[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [alert, setAlert] = useState<AlertState>({
    open: false,
    message: '',
    severity: 'info'
  });

  useEffect(() => {
    fetchGroups();
  }, []);

  const handleAlert = (message: string, severity: AlertState['severity'] = 'info') => {
    setAlert({ open: true, message, severity });
  };

  const fetchGroups = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/customer-groups`);
      if (Array.isArray(response.data)) {
        setGroups(response.data);
      }
    } catch (err) {
      console.error(err);
      handleAlert('Failed to fetch customer groups', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (group: CustomerGroup) => {
    // Implementation will be added later
    console.log('Edit group:', group);
  };

  const handleDelete = async (groupId: number) => {
    try {
      await axios.delete(`${API_BASE_URL}/customer-groups/${groupId}`);
      handleAlert('Group deleted successfully', 'success');
      await fetchGroups();
    } catch (err) {
      console.error(err);
      handleAlert('Failed to delete group', 'error');
    }
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
        <CircularProgress />
      </Box>
    );
  }

  
    return (
      <Box sx={{ width: '100%' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Members</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Created</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Last Message</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groups
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((group) => (
                  <TableRow key={group.id} hover>
                    <TableCell>{group.name}</TableCell>
                    <TableCell>{group.description}</TableCell>
                    <TableCell>
                      <Chip 
                        label={group.member_count} 
                        color="primary" 
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      {new Date(group.created_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {group.last_message_sent 
                        ? new Date(group.last_message_sent).toLocaleDateString()
                        : 'Never'}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => handleEdit(group)}
                        sx={{ mr: 1 }}
                        color="primary"
                      >
                        <EditIcon size={18} />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handleDelete(group.id)}
                        color="error"
                      >
                        <DeleteIcon size={18} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
  
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={groups.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
  
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={() => setAlert({ ...alert, open: false })}
        >
          <Alert 
            onClose={() => setAlert({ ...alert, open: false })} 
            severity={alert.severity}
            sx={{ width: '100%' }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </Box>
    );
  }