import { Toolbar, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { UserButton } from "@clerk/clerk-react";

type Props = {
  toggleDrawer: () => void;
  drawerOpen: boolean;
};

const LogOut = ({ toggleDrawer, drawerOpen }: Props) => {
  return (
    <Toolbar
      sx={{
        pr: "24px",
      }}
    >
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer}
        sx={{
          marginRight: "36px",
          ...(drawerOpen && { display: "none" }),
        }}
      >
        <MenuIcon />
      </IconButton>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        sx={{ flexGrow: 1 }}
      >
        RANI CRM
      </Typography>
      <UserButton afterSignOutUrl="/" />
    </Toolbar>
  );
};

export default LogOut;