import { 
  ClerkProvider, 
  SignedIn, 
  SignedOut, 
  SignIn, 
  SignUp,
} from "@clerk/clerk-react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ModernMain from "./components/ModernMain";
import CustomersPage from "./pages/CustomersPage";
import MainPage from "./routes/mainpage"; 
import Error from "./routes/error";
import AddCustomerPage from "./pages/AddCustomerPage";
import CustomerGroupsPage from "./pages/CustomerGroupsPage";
import SendMessagePage from "./pages/SendMessagePage";
import SettingsPage from "./pages/SettingsPage";
import LandingPage from "./routes/LandingPage";
import AuthLayout from './components/AuthLayout';
import ScrollableContainer from "./components/ScrollableContainer";
// Custom type declarations
declare module '@mui/material/styles' {
  interface Palette {
    neutral?: Palette['primary'];
  }
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }
  interface PaletteColor {
    lighter?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
  }
}

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

// if (!PUBLISHABLE_KEY) {
//   throw new Error("Missing Publishable Key");
// }

// Create MUI theme
const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 8,
          padding: '8px 16px',
          fontWeight: 500,
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          border: '1px solid',
          borderColor: '#e5e7eb',
          boxShadow: 'none',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#2563eb',
      light: '#60a5fa',
      lighter: '#eff6ff',
      contrastText: '#ffffff',
    } as import('@mui/material/styles').PaletteColorOptions,
    secondary: {
      main: '#7c3aed',
      contrastText: '#ffffff',
    },
    background: {
      default: '#f9fafb',
      paper: '#ffffff',
    },
    grey: {
      50: '#f9fafb',
      100: '#f3f4f6',
      200: '#e5e7eb',
      300: '#d1d5db',
      400: '#9ca3af',
      500: '#6b7280',
      600: '#4b5563',
      700: '#374151',
      800: '#1f2937',
      900: '#111827',
    },
  },
  typography: {
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    h1: { fontWeight: 600 },
    h2: { fontWeight: 600 },
    h3: { fontWeight: 600 },
    h4: { fontWeight: 600 },
    h5: { fontWeight: 600 },
    h6: { fontWeight: 600 },
  },
  shape: {
    borderRadius: 8,
  },
});


function ClerkProviderWithRoutes() {
  return (
    <ScrollableContainer className="h-screen">
    <ClerkProvider 
      publishableKey={PUBLISHABLE_KEY}
      afterSignOutUrl="/sign-in"
    >
      <Routes>
        {/* Public Landing Page - Now directly rendered at root */}
        <Route 
          path="/" 
          element={<LandingPage />}
        />

        {/* Auth Routes */}
        <Route 
          path="/sign-in/*" 
          element={
            <>
              <SignedIn>
                <Navigate to="/dashboard" replace />
              </SignedIn>
              <SignedOut>
                <AuthLayout mode="sign-in">
                  <SignIn 
                    routing="path" 
                    path="/sign-in" 
                    redirectUrl="/dashboard"
                    appearance={{
                      elements: {
                        formButtonPrimary: "bg-blue-600 hover:bg-blue-700",
                        footerActionLink: "text-blue-600 hover:text-blue-700",
                        card: "shadow-none",
                        formFieldInput: "border-gray-300 focus:border-blue-500",
                        formFieldLabel: "text-gray-700",
                        headerTitle: "text-2xl font-bold",
                        headerSubtitle: "text-gray-600",
                      },
                    }}
                  />
                </AuthLayout>
              </SignedOut>
            </>
          } 
        />

        <Route 
          path="/sign-up/*" 
          element={
            <>
              <SignedIn>
                <Navigate to="/dashboard" replace />
              </SignedIn>
              <SignedOut>
                <AuthLayout mode="sign-up">
                  <SignUp 
                    routing="path" 
                    path="/sign-up"
                    redirectUrl="/dashboard"
                    appearance={{
                      elements: {
                        formButtonPrimary: "bg-blue-600 hover:bg-blue-700",
                        footerActionLink: "text-blue-600 hover:text-blue-700",
                        card: "shadow-none",
                        formFieldInput: "border-gray-300 focus:border-blue-500",
                        formFieldLabel: "text-gray-700",
                        headerTitle: "text-2xl font-bold",
                        headerSubtitle: "text-gray-600",
                      },
                    }}
                  />
                </AuthLayout>
              </SignedOut>
            </>
          }
        />

        {/* Protected Dashboard Routes */}
        <Route
          path="/dashboard/*"
          element={
            <>
              <SignedIn>
                <ModernMain>
                  <Routes>
                  <Route index element={<MainPage />} />
                    <Route path="customers" element={<CustomersPage />} /> {/* Add this route */}
                    <Route path="customers/add" element={<AddCustomerPage />} />
                    <Route path="customers/groups" element={<CustomerGroupsPage />} />
                    <Route path="messages/send" element={<SendMessagePage/>} />
                    <Route path="messages/history" element={<div>Message History</div>} />
                    <Route path="messages/templates" element={<div>Message Templates</div>} />
                    <Route path="messages/campaigns" element={<div>Campaigns</div>} />
                    <Route path="analytics" element={<div>Analytics Overview</div>} />
                    <Route path="analytics/campaigns" element={<div>Campaign Analytics</div>} />
                    <Route path="analytics/reports" element={<div>Reports</div>} />
                    <Route path="settings" element={<SettingsPage />} />
                    <Route path="*" element={<Error />} />
                  </Routes>
                </ModernMain>
              </SignedIn>
              <SignedOut>
                <Navigate to="/sign-in" replace />
              </SignedOut>
            </>
          }
        />

        {/* Catch-all route */}
        <Route 
          path="*" 
          element={<Error />}
        />
      </Routes>
    </ClerkProvider>
    </ScrollableContainer>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ClerkProviderWithRoutes />
    </ThemeProvider>
  );
}

export default App;