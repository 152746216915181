import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
  IconButton,
  Divider,
  CircularProgress
} from '@mui/material';
import { Save, ArrowLeft, X } from 'lucide-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface CustomerFormData {
  code: string;
  first_name: string;
  last_name: string;
  company_name: string;
  email: string;
  phone: string;
  mobile: string;
  tax_number: string;
  birth_date: string;
  gender: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  notes: string;
  customer_group_id: string;
  price_list_id: string;
  credit_limit: string;
  tax_exempt: boolean;
  tax_exemption_number: string;
  preferred_payment_method: string;
}


interface FormErrors {
  [key: string]: string | undefined;
}
interface AlertState {
  show: boolean;
  message: string;
  severity: 'success' | 'error';
}

const AddCustomerPage: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<AlertState>({ show: false, message: '', severity: 'success' });
  const [formData, setFormData] = useState<CustomerFormData>({
    code: '',
    first_name: '',
    last_name: '',
    company_name: '',
    email: '',
    phone: '',
    mobile: '',
    tax_number: '',
    birth_date: '',
    gender: '',
    address_line1: '',
    address_line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
    notes: '',
    customer_group_id: '',
    price_list_id: '',
    credit_limit: '',
    tax_exempt: false,
    tax_exemption_number: '',
    preferred_payment_method: ''
  });

  const [errors, setErrors] = useState<FormErrors>({});

  const validateForm = () => {
    const newErrors: FormErrors = {};
    
    // Required fields
    if (!formData.first_name.trim()) newErrors.first_name = 'First name is required';
    if (!formData.last_name.trim()) newErrors.last_name = 'Last name is required';
    
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email && !emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }

    // Phone validation
    const phoneRegex = /^\d{10}$/;
    if (formData.phone && !phoneRegex.test(formData.phone.replace(/\D/g, ''))) {
      newErrors.phone = 'Please enter a valid 10-digit phone number';
    }
    if (formData.mobile && !phoneRegex.test(formData.mobile.replace(/\D/g, ''))) {
      newErrors.mobile = 'Please enter a valid 10-digit mobile number';
    }

    // Postal code validation
    const postalRegex = /^\d{5}(-\d{4})?$/;
    if (formData.postal_code && !postalRegex.test(formData.postal_code)) {
      newErrors.postal_code = 'Please enter a valid postal code';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      setAlert({
        show: true,
        message: 'Please correct the errors before submitting.',
        severity: 'error'
      });
      return;
    }

    setLoading(true);
    try {
      const userId = localStorage.getItem("USER_ID");
      const response = await axios.post(`${import.meta.env.VITE_API_URL}/customers`, {
        ...formData,
        user_id: Number(userId)
      });

      if (response.data) {
        setAlert({
          show: true,
          message: 'Customer added successfully!',
          severity: 'success'
        });
        
        // Redirect after a short delay
        setTimeout(() => {
          navigate('/customers');
        }, 1500);
      }
    } catch (error: unknown) {
      console.error('Error adding customer:', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    }
  };

  return (
    <Box className="p-6">
      {/* Header */}
      <Box className="mb-6 flex justify-between items-center">
        <Box className="flex items-center gap-3">
          <IconButton onClick={() => navigate('/customers')} size="small">
            <ArrowLeft />
          </IconButton>
          <Typography variant="h5" className="font-semibold">
            Add New Customer
          </Typography>
        </Box>
        
        <Box className="flex gap-3">
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => navigate('/customers')}
            startIcon={<X size={20} />}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : <Save size={20} />}
          >
            Save Customer
          </Button>
        </Box>
      </Box>

      {/* Alert */}
      {alert.show && (
        <Alert 
          severity={alert.severity} 
          className="mb-6"
          onClose={() => setAlert({ ...alert, show: false })}
        >
          {alert.message}
        </Alert>
      )}

      {/* Form */}
      <Paper elevation={0} className="border p-6">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            {/* Basic Information */}
            <Grid item xs={12}>
              <Typography variant="h6" className="mb-4">Basic Information</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                    error={!!errors.first_name}
                    helperText={errors.first_name}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleInputChange}
                    error={!!errors.last_name}
                    helperText={errors.last_name}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    error={!!errors.phone}
                    helperText={errors.phone || 'Format: 1234567890'}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Mobile"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleInputChange}
                    error={!!errors.mobile}
                    helperText={errors.mobile || 'Format: 1234567890'}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Company Name"
                    name="company_name"
                    value={formData.company_name}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Additional Information */}
            <Grid item xs={12}>
              <Divider className="mb-4" />
              <Typography variant="h6" className="mb-4">Additional Information</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Birth Date"
                    name="birth_date"
                    type="date"
                    value={formData.birth_date}
                    onChange={handleInputChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <FormLabel>Gender</FormLabel>
                    <RadioGroup
                      row
                      name="gender"
                      value={formData.gender}
                      onChange={handleInputChange}
                    >
                      <FormControlLabel value="M" control={<Radio />} label="Male" />
                      <FormControlLabel value="F" control={<Radio />} label="Female" />
                      <FormControlLabel value="O" control={<Radio />} label="Other" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* Address Information */}
            <Grid item xs={12}>
              <Divider className="mb-4" />
              <Typography variant="h6" className="mb-4">Address Information</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address Line 1"
                    name="address_line1"
                    value={formData.address_line1}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address Line 2"
                    name="address_line2"
                    value={formData.address_line2}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="City"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="State"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Postal Code"
                    name="postal_code"
                    value={formData.postal_code}
                    onChange={handleInputChange}
                    error={!!errors.postal_code}
                    helperText={errors.postal_code}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Country"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Business Information */}
            <Grid item xs={12}>
              <Divider className="mb-4" />
              <Typography variant="h6" className="mb-4">Business Information</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Tax Number"
                    name="tax_number"
                    value={formData.tax_number}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Credit Limit"
                    name="credit_limit"
                    type="number"
                    value={formData.credit_limit}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Notes */}
            <Grid item xs={12}>
              <Divider className="mb-4" />
              <Typography variant="h6" className="mb-4">Additional Notes</Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Notes"
                name="notes"
                value={formData.notes}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

export default AddCustomerPage;