import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Box,
    Alert,
    Autocomplete,
    Chip
  } from '@mui/material';
  import { useState, useEffect } from 'react';
  import axios from 'axios';
  
  const API_BASE_URL = import.meta.env.VITE_API_URL || 'https://crm.raniii.com:3000/api/v1';


  interface Customer {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
  }
  
  interface AddCustomerGroupProps {
    open: boolean;
    onClose: () => void;
  }
  
  export default function AddCustomerGroup({ open, onClose }: AddCustomerGroupProps) {
    const [formData, setFormData] = useState({
      name: '',
      description: '',
    });
    const [selectedCustomers, setSelectedCustomers] = useState<Customer[]>([]);
    const [availableCustomers, setAvailableCustomers] = useState<Customer[]>([]);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      fetchCustomers();
    }, []);
  
    const fetchCustomers = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/customers`);
        if (Array.isArray(response.data)) {
          setAvailableCustomers(response.data);
        }
      } catch (err) {
        console.error(err);
        setError('Failed to fetch customers');
      }
    };
  
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    };
  
    const handleSubmit = async () => {
      if (!formData.name) {
        setError('Group name is required');
        return;
      }
  
      setLoading(true);
      try {
        await axios.post(`${API_BASE_URL}/customer-groups`, {
          ...formData,
          customer_ids: selectedCustomers.map(c => c.id)
        });
        onClose();
      } catch (err) {
        console.error(err);
        setError('Failed to create group');
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Create Customer Group</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            <TextField
              fullWidth
              label="Group Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              margin="normal"
              multiline
              rows={3}
            />
            <Autocomplete
              multiple
              options={availableCustomers}
              getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
              value={selectedCustomers}
              onChange={(_, newValue) => setSelectedCustomers(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Customers"
                  margin="normal"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={`${option.first_name} ${option.last_name}`}
                    {...getTagProps({ index })}
                    size="small"
                  />
                ))
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained" 
            disabled={loading}
          >
            Create Group
          </Button>
        </DialogActions>
      </Dialog>
    );
  }