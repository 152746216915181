import React, { useState, useEffect } from 'react';
import { useUser } from "@clerk/clerk-react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  CircularProgress,
  Card,
  CardContent,
  Alert,
  AlertColor
} from '@mui/material';
import {
  Settings as SettingsIcon,
  Notifications as NotificationsIcon,
  Message as MessageIcon
} from '@mui/icons-material';
import Dashboard from '../components/Dashboard';


const SettingsPage = () => {
  const { user } = useUser();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: '', severity: 'success' });
  const [settings, setSettings] = useState({
    smsNotifications: true,
    emailNotifications: true,
    defaultSenderName: '',
    defaultMessage: '',
    dailySMSLimit: 100,
    autoResponseEnabled: false,
    autoResponseMessage: '',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  useEffect(() => {
    // Simulated fetch of user settings
    const fetchSettings = async () => {
      try {
        setLoading(true);
        // In a real implementation, you would fetch from your API
        // const response = await axios.get(...);
        // setSettings(response.data);
        
        // Simulated API delay
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        setLoading(false);
      } catch (error) {
        console.error('Error fetching settings:', error);
        setAlert({
          show: true,
          message: 'Failed to load settings',
          severity: 'error'
        });
        setLoading(false);
      }
    };

    fetchSettings();
  }, [user]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    setSettings(prev => ({
      ...prev,
      [name]: event.target.type === 'checkbox' ? checked : value
    }));
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      // Simulated API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setAlert({
        show: true,
        message: 'Settings saved successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error saving settings:', error);
      setAlert({
        show: true,
        message: 'Failed to save settings',
        severity: 'error'
      });
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Dashboard>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
          <CircularProgress />
        </Box>
      </Dashboard>
    );
  }

  return (
    <Dashboard>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <SettingsIcon /> Settings
        </Typography>
      </Box>

      
            
      {alert.show && (
        <Alert 
          severity={alert.severity as AlertColor} 
          sx={{ mb: 3 }}
          onClose={() => setAlert({ ...alert, show: false })}
        >
          {alert.message}
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* SMS Settings */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                <MessageIcon /> SMS Settings
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  fullWidth
                  label="Default Sender Name"
                  name="defaultSenderName"
                  value={settings.defaultSenderName}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  label="Default Message Template"
                  name="defaultMessage"
                  multiline
                  rows={3}
                  value={settings.defaultMessage}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  type="number"
                  label="Daily SMS Limit"
                  name="dailySMSLimit"
                  value={settings.dailySMSLimit}
                  onChange={handleChange}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={settings.autoResponseEnabled}
                      onChange={handleChange}
                      name="autoResponseEnabled"
                    />
                  }
                  label="Enable Auto-Response"
                />
                {settings.autoResponseEnabled && (
                  <TextField
                    fullWidth
                    label="Auto-Response Message"
                    name="autoResponseMessage"
                    multiline
                    rows={2}
                    value={settings.autoResponseMessage}
                    onChange={handleChange}
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Notification Settings */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                <NotificationsIcon /> Notification Preferences
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={settings.smsNotifications}
                      onChange={handleChange}
                      name="smsNotifications"
                    />
                  }
                  label="SMS Notifications"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={settings.emailNotifications}
                      onChange={handleChange}
                      name="emailNotifications"
                    />
                  }
                  label="Email Notifications"
                />
                <TextField
                  fullWidth
                  label="Timezone"
                  name="timezone"
                  value={settings.timezone}
                  onChange={handleChange}
                  select
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="America/Los_Angeles">Pacific Time (PT)</option>
                  <option value="America/Denver">Mountain Time (MT)</option>
                  <option value="America/Chicago">Central Time (CT)</option>
                  <option value="America/New_York">Eastern Time (ET)</option>
                </TextField>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button
          variant="outlined"
          onClick={() => setSettings({
            smsNotifications: true,
            emailNotifications: true,
            defaultSenderName: '',
            defaultMessage: '',
            dailySMSLimit: 100,
            autoResponseEnabled: false,
            autoResponseMessage: '',
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          })}
        >
          Reset to Defaults
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={saving}
          startIcon={saving ? <CircularProgress size={20} /> : null}
        >
          {saving ? 'Saving...' : 'Save Changes'}
        </Button>
      </Box>
    </Dashboard>
  );
};

export default SettingsPage;